var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container report" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: {
            "tab-remove": function ($event) {
              _vm.showDetailCard = false
              _vm.activeName = "reportTab"
            },
          },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("inspection.report.reportCardName"),
                name: "reportTab",
              },
            },
            [
              _c(
                "div",
                { staticClass: "report-entire-head" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "planForm",
                      staticClass: "head-form",
                      attrs: { model: _vm.queryInspePlan, inline: "" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.company.name") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: { change: _vm.changeCompany },
                              model: {
                                value: _vm.queryInspePlan.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryInspePlan, "companyId", $$v)
                                },
                                expression: "queryInspePlan.companyId",
                              },
                            },
                            _vm._l(_vm.companyList, function (company) {
                              return _c("el-option", {
                                key: company.companyId,
                                attrs: {
                                  label: company.companyName,
                                  value: company.companyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("commons.project") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryInspePlan.projectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryInspePlan, "projectId", $$v)
                                },
                                expression: "queryInspePlan.projectId",
                              },
                            },
                            _vm._l(_vm.projectList, function (item) {
                              return _c("el-option", {
                                key: item.projectId,
                                attrs: {
                                  label: item.projectName,
                                  value: item.projectId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("inspection.planName") } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: _vm.$t("commons.inputPlease"),
                            },
                            model: {
                              value: _vm.queryInspePlan.inspectionPlanName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryInspePlan,
                                  "inspectionPlanName",
                                  $$v
                                )
                              },
                              expression: "queryInspePlan.inspectionPlanName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "head-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.searchTbs },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.search")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table-body",
                  staticStyle: { "margin-top": "16px" },
                },
                [
                  _c("TTable", {
                    ref: "pointTable",
                    attrs: {
                      tbloading: _vm.isLoad,
                      tableData: _vm.tableData,
                      serialNumber: "",
                      tableTitle: _vm.tableTitle,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "statusText",
                        fn: function (scope) {
                          return [
                            scope.row.status == 1
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("commons.activated"))),
                                ])
                              : _c("span", { staticClass: "txGray" }, [
                                  _vm._v(_vm._s(_vm.$t("commons.unopened"))),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "operating",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("TPagination", {
                    on: { initTable: _vm.getInspePlan },
                    model: {
                      value: _vm.tableParams,
                      callback: function ($$v) {
                        _vm.tableParams = $$v
                      },
                      expression: "tableParams",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.showDetailCard
            ? [
                _c(
                  "el-tab-pane",
                  {
                    staticClass: "report-detail",
                    attrs: {
                      label: _vm.$t("inspection.report.detailCardName"),
                      name: "detailTab",
                      closable: "",
                    },
                  },
                  [
                    _c("reportDetail", {
                      ref: "myChild",
                      attrs: { rules: _vm.rules },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "576px",
            "custom-class": "dialog",
            visible: _vm.dialogVisible,
            "show-close": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-head",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", { staticClass: "dialog-head-vertical" }),
              _c("span", { staticClass: "dialog-head-text" }, [
                _vm._v(_vm._s(_vm.$t("inspection.report.selectInspItem"))),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "dialog-main" },
            [
              _c(
                "el-form",
                {
                  ref: "itemForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    rules: _vm.rules,
                    model: _vm.queryReportData,
                    "label-width": "63",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("inspection.position"),
                        prop: "positionId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            disabled: _vm.inspePositionList.length === 1,
                          },
                          on: { change: _vm.changePosition },
                          model: {
                            value: _vm.queryReportData.positionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryReportData, "positionId", $$v)
                            },
                            expression: "queryReportData.positionId",
                          },
                        },
                        _vm._l(_vm.inspePositionList, function (item) {
                          return _c("el-option", {
                            key: item.positionId,
                            attrs: {
                              label: item.positionName,
                              value: item.positionId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("inspection.item"),
                        prop: "itemPlanId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            disabled: _vm.inspeItemList.length === 1,
                          },
                          model: {
                            value: _vm.queryReportData.itemPlanId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryReportData, "itemPlanId", $$v)
                            },
                            expression: "queryReportData.itemPlanId",
                          },
                        },
                        _vm._l(_vm.inspeItemList, function (item) {
                          return _c("el-option", {
                            key: item.itemPlanId,
                            attrs: {
                              label: item.itemName,
                              value: item.itemPlanId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始结束时间", prop: "startEndTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "119%" },
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "range-separator": "至",
                          clearable: false,
                        },
                        model: {
                          value: _vm.queryReportData.startEndTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryReportData, "startEndTime", $$v)
                          },
                          expression: "queryReportData.startEndTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.validateForm } },
                [_vm._v(_vm._s(_vm.$t("commons.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }