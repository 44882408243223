<template>
  <!-- 巡检报表 -->
  <div class="app-container report">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-remove="
        showDetailCard = false;
        activeName = 'reportTab';
      "
    >
      <el-tab-pane
        :label="$t('inspection.report.reportCardName')"
        name="reportTab"
      >
      
      <div class="report-entire-head">
            <el-form
              ref="planForm"
              :model="queryInspePlan"
              inline
              class="head-form"
            >
              <el-form-item :label="$t('base.company.name')">
                <el-select
                  clearable
                  v-model="queryInspePlan.companyId"
                  @change="changeCompany"
                >
                  <el-option
                    v-for="company in companyList"
                    :key="company.companyId"
                    :label="company.companyName"
                    :value="company.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('commons.project')">
                <el-select clearable v-model="queryInspePlan.projectId">
                  <el-option
                    v-for="item in projectList"
                    :key="item.projectId"
                    :label="item.projectName"
                    :value="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('inspection.planName')">
                <el-input
                  clearable
                  v-model="queryInspePlan.inspectionPlanName"
                  :placeholder="$t('commons.inputPlease')"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-button type="primary" class="head-btn" @click="searchTbs">{{
              $t("commons.search")
            }}</el-button>
          </div>
        <div class="table-body" style="margin-top: 16px;">
				<TTable ref="pointTable" :tbloading='isLoad'  :tableData="tableData" serialNumber :tableTitle="tableTitle" >
					
					<template slot='statusText' slot-scope="scope">
						<span v-if="scope.row.status==1" >{{ $t("commons.activated") }}</span>
						<span v-else class="txGray">{{ $t("commons.unopened") }}</span>
					</template>
					<template slot='operating' slot-scope="scope">
						<el-button type="text" @click.stop="openDialog(scope.row)" >详情</el-button>
					</template>
				</TTable>
				<TPagination v-model="tableParams" @initTable="getInspePlan"></TPagination><!-- 分页 -->
			</div>
      </el-tab-pane>
      <template v-if="showDetailCard">
        <el-tab-pane
          :label="$t('inspection.report.detailCardName')"
          name="detailTab"
          closable
          class="report-detail"
        >
          <reportDetail :rules="rules" ref="myChild"></reportDetail>
        </el-tab-pane>
      </template>
    </el-tabs>
    <el-dialog
      width="576px"
      custom-class="dialog"
      :visible.sync="dialogVisible"
      :show-close="false"
      destroy-on-close
      @close="closeDialog"
    >
      <div slot="title" class="dialog-head">
        <span class="dialog-head-vertical"></span>
        <span class="dialog-head-text">{{
          $t("inspection.report.selectInspItem")
        }}</span>
      </div>
      <div class="dialog-main">
        <el-form
          :inline="true"
          :rules="rules"
          :model="queryReportData"
          label-width="63"
          ref="itemForm"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('inspection.position')" prop="positionId">
            <el-select
              v-model="queryReportData.positionId"
              clearable
              :disabled="inspePositionList.length === 1"
              @change="changePosition"
            >
              <el-option
                v-for="item in inspePositionList"
                :key="item.positionId"
                :label="item.positionName"
                :value="item.positionId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('inspection.item')" prop="itemPlanId">
            <el-select
              v-model="queryReportData.itemPlanId"
              clearable
              :disabled="inspeItemList.length === 1"
            >
              <el-option
                v-for="item in inspeItemList"
                :key="item.itemPlanId"
                :label="item.itemName"
                :value="item.itemPlanId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始结束时间" prop="startEndTime">
            <el-date-picker
              v-model="queryReportData.startEndTime"
              type="daterange"
              start-placeholder="开始日期"
      				end-placeholder="结束日期"
              range-separator="至"
              :clearable='false'
              style='width: 119%;'
            >
            </el-date-picker>
          </el-form-item>
          <!--<el-form-item :label="$t('commons.startTime')" prop="startTime">
            <el-date-picker
              v-model="queryReportData.startTime"
              type="date"
              prefix-icon="el-icon-date"
              :placeholder="$t('inspection.report.pleaseChoose')"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('commons.endTime')" prop="endTime">
            <el-date-picker
              v-model="queryReportData.endTime"
              type="date"
              prefix-icon="el-icon-date"
              :placeholder="$t('inspection.report.pleaseChoose')"
              clearable
            >
            </el-date-picker>
          </el-form-item>-->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t("commons.cancel") }}</el-button>
        <el-button type="primary" @click="validateForm">{{
          $t("commons.confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findInspectionPlan } from "@/api/business/inspection/tenant/query";
import { getInspectionItemPage } from "@/api/business/inspection/tenant/plan";
import { getCompanyList } from "@/api/business/base/tenant/project";
import { getProjectList } from "@/api/business/maintenance/tenant/plan";
import { getInspectionOrder } from "@/api/business/inspection/tenant/plan";
import moment from "moment";
import reportDetail from "../reportDetail/reportDetail";
import TTable from '@/components/YTable/TTable.vue';
import TPagination from '@/components/YTable/TPagination.vue';
import pagination from "@/mixins/TTable/pagination";
import seachAndTable from "@/mixins/TTable/seachAndTable";
import TSeach from '@/components/YTable/TSeach.vue';
export default {
	mixins: [pagination,seachAndTable],
  components: { reportDetail,TTable,TPagination },
  data() {
    const judgeStartTime = (rule, value, callback) => {
      if (value && this.queryReportData.endTime) {
        const start = value.getTime();
        const end = this.queryReportData.endTime.getTime();
        if (start > end) {
          return callback(
            new Error(this.$t("inspection.report.startTimeHint"))
          );
        }
      }
      callback();
    };
    const judgeEndTime = (rule, value, callback) => {
      if (value && this.queryReportData.startTime) {
        const start = this.queryReportData.startTime.getTime();
        const end = value.getTime();
        if (end < start) {
          return callback(new Error(this.$t("inspection.report.endTimeHint")));
        }
      }
      callback();
    };
    const judgeItem = (rule, value, callback) => {
      if (
        this.queryReportData.positionId != null &&
        this.inspeItemList.length === 0
      ) {
        return callback(
          new Error(this.$t("inspection.report.reselectPosition"))
        );
      } else {
        if (!value) {
          return callback(new Error(this.$t("validate.required")));
        }
      }
      callback();
    };
    return {
      rules: {
        positionId: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "change",
          },
        ],
        itemPlanId: [{ validator: judgeItem, trigger: "blur", required: true }],
        startTime: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
          { validator: judgeStartTime, trigger: "blur" },
        ],
        startEndTime:[
        	{
            required: true,
            message: this.$t("validate.required"),
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
          { validator: judgeEndTime, trigger: "blur" },
        ],
      },
      tableData:[],
      tableTitle:[
      		{name:'项目名称',prop:"projectName"},
					{name:'计划名称',prop:"inspectionPlanName"},
					{name:'巡检周期',prop:"cycleType"},
					{name:'生效时间',prop:"effectDate"},
					{name:'失效时间',prop:"expireDate"},
					{name:'计划状态',prop:"status",slotName:"statusText",},
					{name:'更新时间',prop:"lastUpdateDateStr"},
					{name:'操作',width:"98",fixed:'right',slotName:"operating",show:true},
      ],
      
      // 巡检计划
      queryInspePlan: {
        companyId: null,
        projectId: null,
        inspectionPlanName: null,
      },
      // 报表数据
      queryReportData: {
        // 巡检位置
        positionId: null,
        // 巡检计划id
        inspectionPlanId: null,
        startEndTime:[],
        // 巡检项id
        itemPlanId: null,
        startTime: null,
        endTime: null,
        rowCount: 10,
        current: 1,
      },
      // 公司
      companyList: [],
      // 项目
      projectList: [],
      // 巡检位置
      inspePositionList: [],
      // 巡检项
      inspeItemList: [],
      // 巡检计划
      inspePlanList: [],
      cycleList: ["每月", "每周", "每日", "每次"],
      activeName: "reportTab", //detailTab
      showDetailCard: false,
      dialogVisible: false,
      isLoad: true,
      checkRows:null,
    };
  },
  created() {
    this.getInspePlan();
    this.getCompany();
  },
  methods: {
  	
  	searchTbs(){
  		this.tableParams.current=1;
  		this.getInspePlan();
  	},
    // 巡检计划
    async getInspePlan() {
      this.isLoad = true;
      this.inspePlanList = [];
      this.tableData=[];
    	let params = {
				...this.queryInspePlan,
				current: this.tableParams.current,
				rowCount: this.tableParams.rowCount,
			}
			let res = await this.ApiHttp('//inspection/tenant/inspection/plan/page',params);
			if(res){
				res.rows.map(item=>{
					item.effectDate= this.common.getTimeMmss(item.effectDate);
        	item.expireDate= this.common.getTimeMmss(item.expireDate);
        	item.lastUpdateDateStr= this.common.getTimeMmss(item.lastUpdateDate);
          item.cycleType= this.cycleList[item.cycleType - 1];
				})
			this.tableData = res.rows||[];
				this.tableParams.total = res.total;
			}
      this.isLoad = false;
//    findInspectionPlan(this.queryInspePlan)
//      .then((res) => {
//        res.forEach((i) => {
//        	let item={
//            inspectionPlanId: i.inspectionPlanId,
//            projectName: i.projectName,
//            inspectionPlanName: i.inspectionPlanName,
//            effectDate: moment(i.effectDate).format("YYYY-MM-DD"),
//            expireDate: moment(i.expireDate).format("YYYY-MM-DD"),
//            cycleType: this.cycleList[i.cycleType - 1],
//            status: i.status,
//         };
//          this.inspePlanList.push(item);
//          this.tableData.push(item);
//        });
//      })
    },
    // 公司
    getCompany() {
      getCompanyList().then((res) => {
        this.companyList = res;
      });
    },
    // 切换公司
    changeCompany(id) {
      this.queryInspePlan.projectId = null;
      this.projectList = [];
      if (id) {
        getProjectList({ companyId: id }).then((res) => {
          this.projectList = res;
        });
      }
    },
    changePosition(id) {
      this.queryReportData.itemPlanId = null;
      this.inspeItemList = [];
      getInspectionItemPage({
        inspectionPlanId: this.queryReportData.inspectionPlanId,
        positionId: id,
        rowCount: 999,
        current: 1,
      }).then((res) => {
        const data = res.rows;
        res.rows.length === 1 &&
          (this.queryReportData.itemPlanId = data[0].itemPlanId);
        this.inspeItemList = data;
        this.dialogVisible = true;
      });
    },
    //  打开对话框
    openDialog(row) {
    	this.checkRows=row
    	let id=row.inspectionPlanId;
      this.queryReportData.inspectionPlanId = id;
      getInspectionOrder({ inspectionPlanId: id }).then((res) => {
        this.inspePositionList = res;
        switch (res.length) {
          case 0:
            this.$message(this.$t("inspection.report.locationHint"));
            break;
          case 1:
            this.queryReportData.positionId = res[0].positionId;
            this.changePosition(res[0].positionId);
            break;
          default:
            this.dialogVisible = true;
        }
      });
    },
    closeDialog() {
      this.$refs["itemForm"].resetFields();
      this.queryReportData.inspectionPlanId = null;
      this.queryReportData.positionId = null;
      this.queryReportData.inspectionPlanId = null;
      this.queryReportData.itemPlanId = null;
      this.queryReportData.startTime = null;
      this.queryReportData.startEndTime=[];
      this.queryReportData.endTime = null;
      this.inspePositionList = [];
      this.inspeItemList = [];
      this.dialogVisible = false;
      this.checkRows=null;
    },
    // 验证表单
    validateForm() {
      this.$refs["itemForm"].validate((valid) => {
        if (valid) {
          this.showDetailCard = true;
          this.activeName = "detailTab";
          this.queryReportData.startTime=this.queryReportData.startEndTime[0];
          this.queryReportData.endTime=this.queryReportData.startEndTime[1];
          this.$nextTick(() => {
            this.$refs.myChild.initQueryParam({
              query: this.queryReportData,
              inspePositionList: this.inspePositionList,
              inspeItemList: this.inspeItemList,
              rows:this.checkRows,
            });
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.txGray{
	color: #999;
}
.report {
  min-height: calc(100vh - 90px);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 28px 20px 20px;
  margin: 20px;

  &-entire {
    ::v-deep .el-input {
      width: 323.5px;
    }

    &-head {
      display: flex;
      margin-top: 5px;

      .head-form {
        width: fit-content;

        .el-form-item {
          margin: 0 30px 0 0;
        }
      }

      .head-btn {
        width: 89px;
        margin-left: 10px;
      }
    }

    &-main {
      display: flex;
      min-height: 200px;
      flex-wrap: wrap;
      margin-right: 103px;

      .main-card {
        // width: 32.086%;
        width: 24%;
        margin-right: calc(4% / 3);
        flex: none;

        ::v-deep .main-card-item {
          width: 100%;
          margin-top: 20px;
          border-radius: 4px 4px 0px 0px;
          border: 1px solid #dcdfe6;
          box-shadow: none !important;

          .el-card__header {
            padding: 12px 15px 12px 20px;
          }

          .el-card__body {
            padding: 3px 20px 20px 20px;
          }

          .item-head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .head-left {
              flex: 1;
              font-size: 16px;
              font-weight: 500;
              color: #303133;
              overflow: hidden; //多出部分隐藏
              white-space: nowrap; //一行显示
              text-overflow: ellipsis; //是否显示省略号
            }

            .head-right {
              flex: none;
              width: 56px;
              height: 28px;
              line-height: 28px;
              font-size: 12px;
              font-weight: 500;
              color: #409eff;
              text-align: center;
              border-radius: 4px;
              border: 1px solid #b3d8ff;
              margin-left: 10px;
            }

            // 未开启
            .unopened-style {
              color: #999999;
              border: 1px solid #dcdfe6;
            }
          }

          .item-detail {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            margin-top: 17px;
            overflow: hidden; //多出部分隐藏
            white-space: nowrap; //一行显示
            text-overflow: ellipsis; //是否显示省略号
          }
        }
      }

      .main-card:nth-child(4n + 1) {
        margin-right: 0;
      }
    }
  }

  ::v-deep .dialog {
    .el-dialog__header {
      padding: 20px 0 0 20px;

      .dialog-head-vertical {
        display: inline-block;
        width: 5px;
        height: 16px;
        background: #409eff;
        vertical-align: middle;
      }

      .dialog-head-text {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        margin-left: 10px;
        vertical-align: middle;
      }
    }

    .el-dialog__body {
      padding: 12px 0 0 30px;

      .el-form-item {
        margin-right: 36px;

        .el-form-item__label {
          font-size: 14px;
          font-weight: 500;
          color: #606266;
        }

        .el-form-item__label:before {
          color: #e02020;
          margin-right: 0;
        }

        .el-form-item__content {
          display: block;

          .el-input {
            width: 240px;
          }
        }
      }

      .el-form-item:nth-child(2n) {
        margin-right: 0;
      }
    }

    .el-dialog__footer {
      padding: 32px 0 20px 0;
      text-align: center;
    }
  }
}
</style>
