import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取巡检模版库
 */
export function getInspectionPage(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/page',
        method: 'get',
        params
    })
}

/**
 * 公司查询
 * @param params
 */
export function getCompanyList() {
    return request({
        url: envInfo.bgApp.organizationPath + '/tenant/company/list',
        method: 'get'
    })
}

/**
 * 项目查询
 * @param params
 */
export function getProjectList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/project/list?companyId='+params.companyId,
        method: 'get'
    })
}

/**
 * 查询子位置树
 * @param params
 */
export function getPositionChildNode(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionChildNode?positionId='+params.positionId,
        method: 'get'
    })
}

/**
 * 查询项目下的位置树
 * @param params
 */
export function getProjectPositionTree(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionTree?projectId='+params.projectId,
        method: 'get'
    })
}

/**
 * 更新巡检清单
 * @param params
 */
export function updateInspectionPosition(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/update',
        method: 'put',
        data: params
    })
}

/**
 * 查询子巡检清单
 * @param params
 */
export function getInspectionOrder(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/list?inspectionPlanId='+params.inspectionPlanId,
        method: 'get'
    })
}

/**
 * 启用巡检计划
 * @param params
 */
export function enableInspection(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/enable',
        method: 'put',
        data: params
    })
}

/**
 * 暂停巡检计划
 * @param params
 */
export function stopInspection(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/suspend',
        method: 'put',
        data: params
    })
}

/**
 * 删除巡检计划
 * @param params
 */
export function deleteInspection(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/del?inspectionPlanId='+params.inspectionPlanId,
        method: 'delete',
    })
}

/**
 * 查询公司下的岗位列表
 * @param params
 */
export function getCompanyDutyList(params) {
    return request({
        url: envInfo.bgApp.organizationPath + '/tenant/duty/deptDutyList?companyId='+params.companyId,
        method: 'get'
    })
}

/**
 * 查询巡检信息
 * @param params
 */
export function getInspectionPlanById(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/findPlanById?inspectionPlanId='+params.inspectionPlanId,
        method: 'get'
    })
}

/**
 * 新建巡检计划
 * @param params
 */
export function createInspectionPlan(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/savePlan',
        method: 'post',
        data : params
    })
}

/**
 * 更新巡检计划
 * @param params
 */
export function updateInspectionPlan(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/plan/updatePlan',
        method: 'put',
        data : params
    })
}

/**
 * 查询位置巡检要求
 * @param params
 */
export function getPositionInspection(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/queryOne',
        method: 'get',
        params
    })
}

/**
 * 更新位置巡检要求
 * @param params
 */
export function updatePositionInspection(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/updateOne',
        method: 'put',
        data: params
    })
}

/**
 * 更新位置巡检要求
 * @param params
 */
export function getInspectionItemPage(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/itemList',
        method: 'get',
        params
    })
}

/**
 * 查询设备列表
 * @param params
 */
export function getDeviceList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/list',
        method: 'get',
        params
    })
}

/**
 * 新增巡检项
 * @param params
 */
export function addItem(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/addItem',
        method: 'post',
        data: params
    })
}

/**
 * 修改巡检项
 * @param params
 */
export function updateItem(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/updateItem',
        method: 'put',
        data: params
    })
}

/**
 * 删除巡检项
 * @param params
 */
export function deleteItem(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/position/delItem',
        method: 'delete',
        params
    })
}
