<template>
	<!--设置列表弹窗-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="列表设置" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="900px" :before-close="closeMypup">
			<div>
				<div class="d_body">
					<!--<el-cascader-panel  v-model="caderVals" :options="options" :props="caderkeyss" filterable></el-cascader-panel>-->
					<el-tree ref='elTree' default-expand-all :data="options" show-checkbox node-key="keys" :props="defaultProps"  :default-checked-keys="dfCheckeds">
					  
					  
					  
					  
					</el-tree>
				
				</div>

				<div class="btmBtn">
					<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
					<el-button type="primary" @click='getCheckedVals'> 提交</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
			options: {
				type: Array,
				default: ()=>{
					return [
						{name:'巡检项名称',keys:"xjxmc",isShow:true,childs:[
			      			{name:'用户',keys:"user",childs:[
			      				{name:'姓名',keys:"source1",},
			      				{name:'年龄',keys:"age"},
			      				
			      			]},
			      			{name:'来源',keys:"source2",isShow:true,},
			      		]},
						{name:'巡检编码',keys:"phone",isShow:true,},
					]
				},
			},
		},
		components: {},
		data() {
			return {
				checkAll:false,
				caderVals:[],
				dfCheckeds:[],//'dutyName','975','selectItemList'
			 	defaultProps: {
		          	children: 'childs',
		          	label: 'name'
		       	},
				caderkeyss:{ 
					multiple: true,value:'keys',label:'name',children:'childs',
				},
			}
		},

		computed: {},
		watch: {
			options:{
				deep:true,
				handler(n,o){
					console.log('options---change',n)
					this.findChekceds();
				}
			},
			
			
		},
		created() {
			this.findChekceds();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			async getCheckedVals(){
//				let Arr1=this.$refs.elTree.getCheckedNodes();
				let Arrs=this.handelItemIsChecked();
				console.log('Arrs',Arrs);
				if(Arrs&&Arrs.length<1){
					this.$message('请选择...')
					return;
				}
				this.$emit('checkEnd',Arrs)
			},
			findChekceds(){
				//查找出需要选中数据
				let Arr=[],childs='childs';
				function findNodeisTrue(dataArr,nodes){
		 			for(let item of dataArr){
		 				
		 				if(item[childs]) {
							findNodeisTrue(item[childs],item);
						}else{
							if(item.isShow){
			 					Arr.push(item.keys);
			 				}
						}
					}
		 		}
				findNodeisTrue(this.options[0].childs)
				console.log('dfCheckeds',Arr)
				this.dfCheckeds=Arr;
				
			},
			handelItemIsChecked(keys,value,childs='childs'){
				//通过已选中的数据 处理成后台所需要的数据格式 
				let ckArr=this.$refs.elTree.getCheckedKeys();
				console.log('ckArr',ckArr);
				if(ckArr.length<1){
					return [];
				}
				if(ckArr.indexOf('all')>-1){
					return false;
				}
		 		function findNodeDatas(dataArr,nodes){
		 			for(let item of dataArr){
		 				if(ckArr.indexOf(item.keys)>-1){
		 					console.log(item.keys)
		 					if(nodes){
		 						nodes.isShow=true;
		 					}
		 					item.isShow=true;
		 				}else{
		 					item.isShow=false;
		 				}
		 				if(item[childs]) {
							findNodeDatas(item[childs],item);
						}
					}
		 		}
		 		let Arrs=JSON.parse(JSON.stringify(this.options));
		 		findNodeDatas(Arrs);
//		 		console.log('findNodeDatas',Arrs);
//		 		let res=[];
//		 		Arrs.map(it=>{
//		 			if(it.isChecked) res.push(it)
//		 		})
		 		return Arrs;
		 	},
			
			closeMypup(){
				this.$emit('close')
			},
		},

	}
</script>

<style lang="scss" scoped>
.d_body{
	max-height: 620px;
	overflow-y: auto;
}
	.btmBtn {
		text-align: right;
	}
</style>