import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取维保模版库
 */
export function getMaintenancePage(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/page',
        method: 'get',
        params
    })
}

/**
 * 公司查询
 * @param params
 */
export function getCompanyList() {
    return request({
        url: envInfo.bgApp.organizationPath + '/tenant/company/list',
        method: 'get'
    })
}

/**
 * 项目查询
 * @param params
 */
export function getProjectList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/project/list?companyId='+params.companyId,
        method: 'get'
    })
}

/**
 * 查询子位置树
 * @param params
 */
export function getPositionChildNode(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionChildNode?positionId='+params.positionId,
        method: 'get'
    })
}

/**
 * 查询项目下的位置树
 * @param params
 */
export function getProjectPositionTree(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionTree?projectId='+params.projectId,
        method: 'get'
    })
}

/**
 * 更新维保清单
 * @param params
 */
export function updateMaintenancePosition(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/update',
        method: 'put',
        data: params
    })
}

/**
 * 查询子维保清单
 * @param params
 */
export function getMaintenanceOrder(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/list?maintenancePlanId='+params.maintenancePlanId,
        method: 'get'
    })
}

/**
 * 启用维保计划
 * @param params
 */
export function enableMaintenance(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/enable',
        method: 'put',
        data: params
    })
}

/**
 * 暂停维保计划
 * @param params
 */
export function stopMaintenance(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/suspend',
        method: 'put',
        data: params
    })
}

/**
 * 删除维保计划
 * @param params
 */
export function deleteMaintenance(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/del?maintenancePlanId='+params.maintenancePlanId,
        method: 'delete',
    })
}

/**
 * 查询公司下的岗位列表
 * @param params
 */
export function getCompanyDutyList(params) {
    return request({
        url: envInfo.bgApp.organizationPath + '/tenant/duty/deptDutyList?companyId='+params.companyId,
        method: 'get'
    })
}

/**
 * 查询维保信息
 * @param params
 */
export function getMaintenancePlanById(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/findPlanById?maintenancePlanId='+params.maintenancePlanId,
        method: 'get'
    })
}

/**
 * 新建维保计划
 * @param params
 */
export function createMaintenancePlan(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/savePlan',
        method: 'post',
        data : params
    })
}

/**
 * 更新维保计划
 * @param params
 */
export function updateMaintenancePlan(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/plan/updatePlan',
        method: 'put',
        data : params
    })
}

/**
 * 查询位置巡检要求
 * @param params
 */
export function getPositionMaintenance(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/queryOne',
        method: 'get',
        params
    })
}

/**
 * 更新位置巡检要求
 * @param params
 */
export function updatePositionMaintenance(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/updateOne',
        method: 'put',
        data: params
    })
}

/**
 * 更新位置巡检要求
 * @param params
 */
export function getMaintenanceItemPage(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/itemList',
        method: 'get',
        params
    })
}

/**
 * 查询设备列表
 * @param params
 */
export function getDeviceList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/list',
        method: 'get',
        params
    })
}

/**
 * 新增巡检项
 * @param params
 */
export function addItem(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/addItem',
        method: 'post',
        data: params
    })
}

/**
 * 修改巡检项
 * @param params
 */
export function updateItem(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/updateItem',
        method: 'put',
        data: params
    })
}

/**
 * 删除巡检项
 * @param params
 */
export function deleteItem(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/position/delItem',
        method: 'delete',
        params
    })
}
