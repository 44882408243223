<template>
	<!--巡检报表-->
	<div class="r-tbs">
		<TSeach :topSearch="topSearch" v-model="seachData" >
			<template slot='btnSlot'>
				<div class="eventBtn rightBtnGroup">
					<el-button class="itBtn" type="primary" :disabled='!seachData.positionId||!seachData.itemPlanId||!seachData.startEndTime||seachData.startEndTime.length<2' :loading="tbloading" @click="seach">{{$t("commons.search")}}</el-button><!-- 查询 -->
					<el-button class="itBtn" type="primary"   @click="exportData">导出</el-button><!-- 导出 -->
					<el-button class="itBtn" type="primary" @click='tbCells.open=true'>列表设置</el-button><!-- 列表设置 -->
				</div>
			</template>
		</TSeach>
		<div class="table-body">
			<div :key='hdResetNum'>
				<TTable ref="myTable" :maxHeight='728' :tbloading='tbloading' alignType='center' :tableData="tableData" serialNumber serialFixed='left' :tableTitle="tableTitle">
					<template slot='operating' slot-scope="scope">
						<!--<el-button type="text" @click.stop="tbRowEdit(scope.row)" >编辑</el-button>-->
					</template>
					<template slot='rowCode' slot-scope="scope">
							<el-button type='text' @click='jumpDetail(scope.row)'>{{scope.row.inspectionJobCode}}</el-button>
					</template>
					<template slot='imgVedios' slot-scope="scope">
							<span v-if="!scope.row.inspectionResource||scope.row.inspectionResource.length<1">-</span>
							<TImgVideo :model='scope.row.inspectionResource' itWidth='40px' itHeight='40px'/> 
					</template>
				</TTable>
			</div>
		</div>
		<TPagination v-model="tableParams" @initTable="initTable"></TPagination>
		<TSetCells v-if='tbCells.open' :isOpen='tbCells.open' :options='cellChecks' @close='tbCells.open=false' @checkEnd='saveDiyCells' />
	</div>
	
</template>

<script>
	import { getInspectionItemPage } from "@/api/business/inspection/tenant/plan";
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import TForm from '@/components/YTable/TForm.vue';
	import TSetCells from '@/components/YTable/TSetCells.vue';
	import TImgVideo from '@/components/YTable/TImgVideo.vue';
	import { envInfo } from "@/constants/envInfo";
	import { cloneDeep } from "lodash";
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TSeach,TPagination,TForm,TSetCells,TImgVideo},
		data() {
			return {
				addModel:{//新增，编辑的弹框信息
		      		open:false,
		      		editRowId:'',
		      	},
		      	tbCells:{
		      		open:false,
		      	},
		      	tbloaded:true,
		      	hdResetNum:1,
		      	positionList: [],
		      	cellOptions:[
//		      		{name:'巡检编码',keys:"phone",isShow:true,},
		      	],
		      	cellChecks:[
		      		{name:'全部',keys:"all",isShow:true,childs:[]},
		      	],
		      	templateId:'',
		      	query: {
			        // 巡检位置
			        positionId: null,
			        // 巡检计划id
			        inspectionPlanId: null,
			        // 巡检项id
			        itemPlanId: null,
			        startTime: null,
			        endTime: null,
			        rowCount: 10,
			        current: 1,
		      	},
			};
		},
		created() {
			
			this.initSearchPart();
			
		},
		mounted() {},
		computed: {},
		methods: {
			async initQueryParam(data) {
		      	this.query = cloneDeep(data.query);
		      	this.positionList = JSON.parse(JSON.stringify(data.inspePositionList));
//				const {inspectionPlanId}=this.query;
//				if(inspectionPlanId){
//					console.log('inspectionPlanId',inspectionPlanId)
//				}
		      	this.itemList = JSON.parse(JSON.stringify(data.inspeItemList));
		      	this.common.injectionOption('positionId',this.positionList,this.topSearch);
		    	this.common.injectionOption('itemPlanId',this.itemList,this.topSearch);
		    	this.seachData.positionId=this.query.positionId;
		    	this.seachData.itemPlanId=this.query.itemPlanId;
		    	this.seachData.startEndTime=[this.query.startTime,this.query.endTime];
		    	this.getTemplatId();
//		    	this.itemList.map(it=>{
//		      		if(it.itemPlanId==this.seachData.itemPlanId){
//		      			this.templateId=it.templateId;
//		      			console.log('templateId',this.templateId)
//		      		}
//		      	})
//		      	await this.initTableTitles();
//		      	this.initTable();
		    },
		    async getTemplatId(){
		    	this.itemList.map(it=>{
		      		if(it.itemPlanId==this.seachData.itemPlanId){
		      			this.templateId=it.templateId;
		      		}
		      	})
		    	console.log('templateId',this.templateId)
		    	await this.initTableTitles();
		      	this.initTable();
		    },
		    initSearchPart(){
		    	const seachArr = [
			    	{name:'巡检位置',type:"select",bindKeys:"positionId",defaultValue:'',option:[],labelName:'positionName',valueName:'positionId',notClear:true,changMethod:this.changePosition},
			    	{name:'巡检项',type:"select",bindKeys:"itemPlanId",defaultValue:'',option:[],labelName:'itemName',valueName:'itemPlanId',notClear:true,changMethod:this.getTemplatId},
			    	{name:'选择时间',type:"daterange",bindKeys:"startEndTime",notClear:true,defaultValue:[]},
					]
					this.topSearch = seachArr;//参数需注入到minxs
		    },
		    jumpDetail(row){
//		    	row.inspectionJobId=5499;
		    	let params = {
		        path: "/tenant/inspection/inspectionSearch/detail",
	      		query: {
		          inspectionJobId:row.inspectionJobId,
			    		positionJobId:row.positionJobId,//'37085'
//			    		itemJobId:row.itemJobId,//'21236'
		        },
		    	};
	      	this.$router.push(params);
		    },
		    changePosition(id) {
		    	console.log('5555555',id)
		      this.seachData.itemPlanId = null;
		      this.itemList = [];
		      getInspectionItemPage({
		        inspectionPlanId: this.query.inspectionPlanId,
		        positionId: id,
		        rowCount: 999,
		        current: 1,
		      }).then((res) => {
		        this.itemList = res.rows;
		        this.common.injectionOption('itemPlanId',this.itemList,this.topSearch);
		      });
		    },
		    async initTableTitles(){
		    	let params={
		    		inspectionPlanId: this.query.inspectionPlanId,
		    		positionId:this.seachData.positionId,
		    		templateId:this.templateId,
		    		isloading:false,
		    	}
		  		let res = await this.ApiHttp('/inspection/tenant/inspection/reportForm/getPersonalField',params,'get');
		  		console.log(888888,res)
		  		if(res){
		  			this.cellOptions=res;
		  			this.cellChecks[0].childs=res;
		  			this.initTbConfig();
		  		}
		  	},
			initTbConfig(childs='childs'){
				//表格配置
				this.tbloaded=false;
				const tableTitle = [];
				function filterNodeisTrue(dataArr,nodes){
					let webDex=0;
		 			for(let i=0;i<dataArr.length;i++){
		 				let item=dataArr[i];
		 				item.width=170;
		 				if(item.keys=='inspectionResource'){
		 					item.slotName="imgVedios";
		 					item.width=428;
		 				}
		 				if(item.keys=='inspectionJobCode'){
		 					item.slotName="rowCode";
		 				}
		 				
	 					if(i<2) item.fixed=true;
		 				item.prop=item.keys;
//		 				item.align='center'
		 				if(nodes){
		 					//前端需要用到的字段，对应表格 webKeys 的取值字段 规则保持一致； 父级key+当前key
		 					item.prop=nodes.keys+'child'+webDex;
		 					webDex++;
		 					if(nodes.keys=='checkItemList'){
		 						item.diyClass='txRed'
		 					}
		 				}
		 				if(!item.isShow){
		 					dataArr.splice(i,1);
		 					i--
		 				}
		 				if(item[childs]) {
							filterNodeisTrue(item[childs],item);
						}
					}
		 		}
				
//				let tbTitles=[
//					{name:'AAA',keys:"AA",isShow:true,},
//					{name:'BBB',keys:"BB",isShow:false,},
//					{name:'CCC',keys:"CC",isShow:true,childs:[
//						{name:'C1',keys:"C1",isShow:true,},
//						{name:'C2',keys:"C2",isShow:false,},
//						{name:'C3',keys:"C3",isShow:false,},
//					]},
//					{name:'DDD',keys:"DD",isShow:true,},
//					{name:'EEE',keys:"EE",isShow:true,},
//				]
				let tbTitles=JSON.parse(JSON.stringify(this.cellOptions));
				filterNodeisTrue(tbTitles);
				console.log('-----tbTitles-',JSON.parse(JSON.stringify(tbTitles)))
				
				
				this.tableTitle = tbTitles;
				this.hdResetNum++;
//				setTimeout(()=>{
//					this.tbloaded=true;//表头加载完成
//				},1000)
			},
			
			async initTable(){
				let params = {
					...this.seachData,
					inspectionPlanId: this.query.inspectionPlanId,
					templateId:this.templateId,
					positionId:this.seachData.positionId,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				if(params.startEndTime.length>0){
				 	params.startTime=params.startEndTime[0].getTime();
    				params.endTime=params.startEndTime[1].getTime()+ 1000 * 60 * 60 * 24 - 1;
				}
				delete params.startEndTime;
//				delete params.positionId;
	  			this.tbloading=true;
	  			let res =await this.ApiHttp('/inspection/tenant/inspection/reportForm/page',params);
	  			if(res){
	  				let typeObjs={
	  					checkItemList:{name:"检查项",idKey:'checkItemId',nameKey:'checkItemJobName'},
	  					copyItemList:{name:"抄录项",idKey:'copyItemId',nameKey:'copyItemJobName'},
							selectItemList:{name:"选择项",idKey:'selectItemId',nameKey:'selectItemJobName'},
							scoreItemList:{name:"评分项",idKey:'scoreItemId',nameKey:'scoreItemJobName'},
	  				}
					res.rows.map(its=>{
						its.planStartTime=this.common.getTimeMmss(its.planStartTime);
						its.creationDate=this.common.getTimeMmss(its.creationDate);
						its.planEndTime=this.common.getTimeMmss(its.planEndTime);
						its.startDate=this.common.getTimeMmss(its.startDate);
						its.endTime=this.common.getTimeMmss(its.endTime);
						its.endDate=this.common.getTimeMmss(its.endDate);
						for(let keys in its){
							let finds=typeObjs[keys];
							if(finds){
								let arr=its[keys];
								arr.map((items,dex)=>{
									let webKeys=keys+'child'+dex;//前端需要用到的字段，对应表头的字段 规则保持一致；
									let webVlas;
									if(items){
										switch(keys){
										    case 'checkItemList':
										    	let typeStr=['','√','x'];
										        webVlas=typeStr[items.status];
										        break;
										    case 'copyItemList':
										    		let name=items.copyItemValue?items.copyItemValue:'';
										    		let vlas=items.copyItemUnitName?items.copyItemUnitName:'';
										        webVlas=name?name+vlas:'';
										        break;
								         	case 'selectItemList':
								         		let tempArray = [];
								            tempArray = items.selectSonItemJobList.map((_is) => (_is.checked === "true" ? _is.describe : ""));
								            tempArray = tempArray.filter((_is) => _is);
								            webVlas =tempArray.length != 0 ? tempArray.join("；") : '';
										        break;
									        case 'scoreItemList':
										        webVlas=items.score;
										        break;
										    default:
										        webVlas='';
										}
										items.webItsVal=webVlas;
									}
									its[webKeys]=webVlas;
								})
							}

							
						}
						
						
					})
	  				
	  				console.log('-------',JSON.parse(JSON.stringify(res)))
	  				
	  				this.tableData = res.rows;
						this.tableParams.total = res.total;
						this.tbloading=false;
	  			}
			},
			exportData() {
				//导出数据
				const [startTime,endTime]=this.seachData.startEndTime;
				if(!startTime||!endTime){
					this.$message('请选择时间');
					return;
				}
		      	const url =
		        envInfo.bgApp.inspectionPath +
		        `/tenant/inspection/reportForm/export?itemPlanId=${
		          this.seachData.itemPlanId
		        }&inspectionPlanId=${
		          this.query.inspectionPlanId
		        }&templateId=${this.templateId}&positionId=${this.seachData.positionId}
		        &startTime=${startTime.getTime()}&endTime=${
		          endTime.getTime() + +1000 * 60 * 60 * 24 - 1
		        }`;
		      	location.href = url;
		    },
			async saveDiyCells(vals){
				//保存选择的自定义列
				let volist=[];
				if(vals) volist=vals[0].childs;
				let params={
		    		templateId:this.templateId,
		    		inspectionPlanId:this.query.inspectionPlanId,
		    		positionId:this.seachData.positionId,
		    		personalFieldVOList:volist,
		    	}
				console.log('自定义列',params)
				let res =await this.ApiHttp('/inspection/tenant/inspection/reportForm/createPersonalField',params,'post');
				console.log('自定义列',res,vals,params)
				this.$message({type:'success',message:'操作成功'})
				this.tbCells.open=false;
				this.initTableTitles();
				this.initTable();
			},
			tbRowAdd(){
				this.addModel={
		      		open:true,
		      		editRowId:'',
		      	};
			},
			tbRowEdit(row){
				this.addModel={
		      		open:true,
		      		editRowId:row.userId,
		      	};
			},
		},
	};
</script>

<style >
	.txRedx{
		color: red;
	}
	.r-tbs .playIcon{
		font-size: 28px !important;
	}
</style>